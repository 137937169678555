// extracted by mini-css-extract-plugin
export var stepsWrap = "Steps-module--stepsWrap--2B2U7";
export var titleShade = "Steps-module--titleShade--15siZ";
export var steps = "Steps-module--steps--1OR4E";
export var stepItem = "Steps-module--stepItem--1K9ur";
export var stepnum = "Steps-module--stepnum--SvpTp";
export var steptitle = "Steps-module--steptitle--3XlOA";
export var icon = "Steps-module--icon--3_4Lm";
export var list = "Steps-module--list--1xdx8";
export var notMargin = "Steps-module--notMargin--3qzsK";
export var mobsteps = "Steps-module--mobsteps--Lc61k";
export var objSlider = "Steps-module--objSlider--Qrqsn";