// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--2GJgl";
export var footerMiddle = "Footer-module--footerMiddle--3oU_m";
export var footerMiddleLeft = "Footer-module--footerMiddleLeft--PDLvX";
export var footerMiddleRight = "Footer-module--footerMiddleRight--3x-6P";
export var footerText = "Footer-module--footerText--1DnVp";
export var decorLine = "Footer-module--decorLine--fggzy";
export var social = "Footer-module--social--3dw5E";
export var footerCopy = "Footer-module--footerCopy--1SL_D";
export var copyLeft = "Footer-module--copyLeft--3e2B-";
export var copyRight = "Footer-module--copyRight--3w6BB";
export var logo = "Footer-module--logo--3u1Kk";
export var you = "Footer-module--you--1BAYK";