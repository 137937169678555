// extracted by mini-css-extract-plugin
export var addressWrap = "Forma-module--addressWrap--3pc7B";
export var addressTop = "Forma-module--addressTop--2Ld3O";
export var addressBottom = "Forma-module--addressBottom--1_90R";
export var addrTitle = "Forma-module--addrTitle--LJijJ";
export var inputWrap = "Forma-module--inputWrap--SK-tZ";
export var addr = "Forma-module--addr--2epgc";
export var phonesMobWrap = "Forma-module--phonesMobWrap--12JEg";
export var phone = "Forma-module--phone--1v624";
export var mail = "Forma-module--mail--27StK";
export var schedule = "Forma-module--schedule--253X0";
export var formBtn = "Forma-module--formBtn--2Nyac";
export var hideForm = "Forma-module--hideForm--3PXHQ";
export var thankWrap = "Forma-module--thankWrap--2yLu1";
export var thanktitle = "Forma-module--thanktitle--38uuG";
export var thanktext = "Forma-module--thanktext--11NHd";