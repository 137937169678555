// extracted by mini-css-extract-plugin
export var contentWrap = "Modal-module--contentWrap--2Hq2t";
export var leftItem = "Modal-module--leftItem--159JV";
export var rightItem = "Modal-module--rightItem--3Pfxe";
export var images = "Modal-module--images--9dzg7";
export var imgWrap = "Modal-module--imgWrap--2CxA4";
export var imgModalWrap = "Modal-module--imgModalWrap--2auIA";
export var closebtn = "Modal-module--closebtn--31ZsC";
export var mobImages = "Modal-module--mobImages--3VKsg";
export var objSlider = "Modal-module--objSlider--2BPZk";
export var popCenter = "Modal-module--popCenter--C87jC";