// extracted by mini-css-extract-plugin
export var carouselWrap = "Slider-module--carouselWrap--3XF4H";
export var caption = "Slider-module--caption--3TBYx";
export var decor = "Slider-module--decor--2l638";
export var decortext = "Slider-module--decortext--2NNA5";
export var text = "Slider-module--text--RtGJf";
export var index = "Slider-module--index--28NZW";
export var mobBtn = "Slider-module--mobBtn--2lbL2";
export var socWrap = "Slider-module--socWrap--3eQsx";
export var socialWrap = "Slider-module--socialWrap--xH5Lp";
export var textLink = "Slider-module--textLink--1aLqR";
export var decorLine = "Slider-module--decorLine--2OKqC";
export var social = "Slider-module--social--3va86";
export var you = "Slider-module--you--PMAoE";