// extracted by mini-css-extract-plugin
export var priceSection = "Price-module--priceSection--1brwk";
export var priceWrap = "Price-module--priceWrap--31IwO";
export var priceItem = "Price-module--priceItem--3eVMG";
export var vid = "Price-module--vid--13BHz";
export var za = "Price-module--za--1-YR3";
export var price = "Price-module--price--1DUNo";
export var priceTitle = "Price-module--priceTitle--3tBo7";
export var priceBtn = "Price-module--priceBtn--GRaeu";
export var decor = "Price-module--decor--2Xn0H";
export var text = "Price-module--text--nP2jL";
export var mobPrice = "Price-module--mobPrice--35qNi";
export var objSlider = "Price-module--objSlider--3SPnW";