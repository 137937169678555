// extracted by mini-css-extract-plugin
export var choose = "Choose-module--choose--1YYxl";
export var titleWrap = "Choose-module--titleWrap--3-gNL";
export var title = "Choose-module--title--28iCd";
export var info = "Choose-module--info--zPUIl";
export var itemWrap = "Choose-module--itemWrap--3Ls_f";
export var item = "Choose-module--item--JOX5d";
export var num = "Choose-module--num--3Gr9Z";
export var infotitle = "Choose-module--infotitle--3BjX2";
export var imageWrap = "Choose-module--imageWrap--3KTNh";
export var iconswrap = "Choose-module--iconswrap--2Uqa6";
export var iconItem = "Choose-module--iconItem--21h_w";